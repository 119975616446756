import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import PageTemplateDetails from '../components/PageTemplateDetails';

class PageTemplate extends React.Component {
	render() {
		const { title, subtitle } = this.props.data.site.siteMetadata;
		const page = this.props.data.markdownRemark;
		const { title: pageTitle, description: pageDescription } = page.frontmatter;
		const description = pageDescription !== null ? pageDescription : subtitle;

		return (
			<Layout>
				<div>
					<Helmet>
						<title>{`${pageTitle} - ${title}`}</title>
						<meta name="description" content={description} />
					</Helmet>
					<PageTemplateDetails {...this.props} />
				</div>
			</Layout>
		);
	}
}

export default PageTemplate;

export const pageQuery = graphql`
	query PageBySlug($slug: String!) {
		site {
			siteMetadata {
				title
				subtitle
				copyright
				menu {
					label
					path
				}
				author {
					name
					email
					twitter
					github
				}
			}
		}
		markdownRemark(fields: { slug: { eq: $slug } }) {
			id
			html
			frontmatter {
				title
				date
				description
			}
		}
	}
`;
